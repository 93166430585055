<template>
  <WeModal
    v-if="detail"
    v-on:close="onClose"
    v-bind:title="getTitle"
    modal-width="80"
  >
    <div
      slot="body"
      v-bind:class="{ 'custom-scrollbar modal-body-scroll pr-3': !showDetail }"
    >
      <span v-print="printConfig" ref="printButton"> </span>
      <div v-if="!loading && !showDetail" v-bind:id="printConfig.id">
        <div class="print">
          <div class="text-center">
            <h5 class="font-weight-bold">
              {{ getDetailDate }} - Sipariş Listesi
            </h5>
          </div>
        </div>
        <WeTable
          v-bind:index="false"
          v-bind:columns="columns"
          v-bind:data="detail.orders"
          v-bind:exportables="exportables"
          v-on:on-export="onExport"
          v-bind:actions="actions"
          v-on:on-action="onAction"
          v-bind:paginatable="paginatable"
          v-bind:page-details="pageDetails"
          v-bind:limitable="limitable"
          v-bind:searchable="searchable"
          ><template
            slot="footer"
            v-if="paginatable && detail.orders && detail.orders.length"
          >
            <tr>
              <td v-bind:colspan="columns.length" class="border-0 text-right">
                <div class="d-inline-block">
                  <span class="font-weight-bold"
                    >Toplam Tutar : {{ detail.tableTotalPrice }}
                  </span>
                </div>
              </td>
            </tr>
          </template>
        </WeTable>
        <div class="col-12 text-right" v-if="!paginatable">
          <span class="font-weight-bold"
            >Toplam Tutar : {{ detail.tableTotalPrice }}
          </span>
        </div>
      </div>
      <WeOrderDetail
        v-else-if="showDetail && ready"
        v-bind:order="orderDetail"
        v-bind:site-logo="siteLogo"
        v-bind:erp-config="erpConfig"
        v-on:on-close="onClose(true)"
      />
      <div class="position-relative" style="min-height: 250px" v-else>
        <WeLoading />
      </div>
    </div>
  </WeModal>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "OrderReportDetail",
  props: {
    detail: {
      default: null,
    },
    loading: {
      default: true,
    },
  },
  data() {
    return {
      ready: false,
      showDetail: false,
      paginatable: true,
      pageDetails: true,
      limitable: true,
      searchable: true,
      printConfig: {
        id: "printOrderList",
        popTitle: "Sipariş Raporları",
      },
      columns: [
        { name: "order_no", th: "Sipariş No", type: "string" },
        {
          name: "source",
          th: "Sipariş Kaynağı",
          type: "string",
          image_width: 60,
        },
        {
          name: "products_count",
          th: "Ürün Adeti",
          type: "integer",
        },
        { name: "total_price", th: "Sipariş Tutarı", type: "currency" },
        { name: "total_discount", th: "İndirim", type: "currency" },
        { name: "total_cargo", th: "Kargo", type: "currency" },
        { name: "total_tax", th: "KDV", type: "currency" },
        { name: "total_amount", th: "Net Tutar", type: "currency" },
      ],
      actions: [
        {
          icon: "fas fa-eye",
          class: "btn-outline-indigo",
          action: "detail",
          tooltip: "Sipariş Detayı",
        },
      ],
      exportables: ["excel", "pdf", "print"],
      order: null,
    };
  },
  methods: {
    ...mapActions("orderDetailModal", ["getById"]),
    ...mapActions("tableExport", ["exportData"]),
    onAction(data) {
      if (data.key == "detail") {
        this.showDetail = true;
        this.getOrderById(data.row.id);
      }
    },
    getOrderById(id) {
      this.ready = false;
      this.getById({
        id: id,
        onSuccess: (result) => {
          this.order = result.data.item;
        },
        onFinish: () => {
          this.ready = true;
        },
      });
    },
    onClose(fromDetail = false) {
      if (fromDetail) {
        this.showDetail = false;
      } else {
        this.showDetail = false;
        this.$emit("close");
      }
    },
    onExport(data) {
      if (data.name !== "print") {
        let fileName = this.getDetailDate + " - Sipariş Raporları";

        this.exportData({
          route: "report/daily-order-detail/export",
          exportable: data,
          fileName: fileName,
          filter: {
            ...this.detail.filter,
            date: this.detail.date,
            type: data.name,
          },
        });
      } else {
        this.paginatable = false;
        this.pageDetails = false;
        this.limitable = false;
        this.searchable = false;

        this.detail.orders.sort();
        this.$refs.printButton.click();

        setTimeout(() => {
          this.paginatable = true;
          this.pageDetails = true;
          this.limitable = true;
          this.searchable = true;

          this.detail.orders.sort();
        }, 100);
      }
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
      orderDetail: (state) => state.orderDetailModal,
    }),
    siteLogo() {
      return this.config["site.logo"] || null;
    },
    erpConfig() {
      return (
        this.config["site.erp_integration"] == "1" ||
        this.config["site.erp_integration"] == 1
      );
    },
    getTitle() {
      let result = this.detail.title;

      if (this.showDetail && this.order) {
        result = null; // "Sipariş Detayı | " + this.order.order_no;
      }

      return result;
    },
    getDetailDate() {
      return new Date(this.detail.date).toLocaleDateString("tr-TR");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-body-scroll {
  max-height: 500px;
  overflow-x: auto;
}
</style>
